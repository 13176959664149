import { Injectable } from '@angular/core';
import ResultModel from '../../../models/result';
import {RequestService} from '../request/request.service';
import UserModel from '../../../models/user';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {

  public user: UserModel;

  public redirectUrl: string = '';

  constructor(private reqService: RequestService, private router: Router) { }

  public async login(email:string, password: string) {
    let user = await this.reqService.postRequest<ResultModel<UserModel>>(`api/index.php/login`, {email, password});

    this.user = user.data;
    //this.user = { id: '1', firstname: 'Nico', lastname: 'Becker', email:'nico.becker@falke.com'};

    if (user.success) {
      this.router.navigate([this.redirectUrl]).then();
    } else {
      alert(user.error);
    }

  }

  public async logout() {
    await this.reqService.getRequest<ResultModel<UserModel>>(`api/index.php/logout`);
    this.user = null;
    this.router.navigate(['login']).then();
  }

  public async checkLogin() {
    let user = await this.reqService.getRequest<ResultModel<UserModel>>(`api/index.php/checklogin`);
    this.user = user.data;
    return user.success;
  }
}
