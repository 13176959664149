import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import '../styles/styles.scss'
import { AppComponent } from './app.component';
import {RouterModule} from '@angular/router';
import {ROUTES} from './app.routes';
import { HttpClientModule} from '@angular/common/http';
import {AuthGuard} from './core/gurads/auth/auth.guard';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    RouterModule.forRoot(ROUTES),
  ],
  providers: [AuthGuard],
  exports: [
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
