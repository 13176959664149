import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthenticateService} from '../../services/authenticate/authenticate.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private auth: AuthenticateService, private router: Router) {}

  /**
   * calls the checklogin
   * @param next
   * @param state
   */
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
    let url: string = state.url;
    return await this.checkLogin(url);
  }

  /**
   * checks if the user is already logged in and sets the redirect url if not
   * @param url the current url
   */
  private async checkLogin(url: string) {
    if (await this.auth.checkLogin()) { return true; }

    // Store the attempted URL for redirecting
    this.auth.redirectUrl = url;

    // Navigate to the login page with extras
    this.router.navigate(['/login']).then();
    return false;
  }
}
