import { EventEmitter, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse, HttpEvent,
  HttpHeaders, HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';

/**
 * The service for http requests
 */

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  public onHeaderDataReceived = new EventEmitter<HttpHeaders>();

  private readonly baseUrl: string;
  private httpHeaders: HttpHeaders;

  constructor(private http: HttpClient) {
    this.baseUrl = 'http://dms.projekte-bkm.de/';
  }

  public async getRequest<T>(url: string, options?: any): Promise<T> {
    return this.requestWithHandlers<T>('GET', url, options);
  }

  public async postRequest<T>(url: string, body?: FormData | any, options?: any): Promise<T> {
    return this.requestWithHandlers<T>('POST', url, body, options);
  }

  public async patchRequest<T>(url: string, body?: FormData | any, options?: any): Promise<T> {
    return this.requestWithHandlers<T>('PATCH', url, body, options);
  }

  public async deleteRequest<T>(url: string, options?: any): Promise<T> {
    return this.requestWithHandlers<T>('DELETE', url, options);
  }

  private requestWithHandlers<T>(method: 'GET' | 'POST' | 'PATCH' | 'DELETE', url: string,
                                 body?: FormData | any, options?: any): Promise<T> {
    if (typeof options !== 'object') {
      options = {};
    }

    options.observe = 'response';

    return new Promise<any>((resolve, reject) => {
      const request = this.request<T>(method, url, body, options);
      request.subscribe((event) => {
        if (options.onHttpEvent) {
          options.onHttpEvent(event);
        }
        if (event instanceof HttpResponse) {
          this.handleHeaders(event);

          resolve(event.body);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  private request<T>(method: 'GET' | 'POST' | 'PATCH' | 'DELETE', url: string,
                     body?: FormData | any, options?: any): Observable<HttpEvent<T>> {
    // Applies the base url
    url = this.baseUrl + url;
    console.log(url);
    // Defines the headers
    options.headers = this.httpHeaders;

    // The body is not a form data but a json object
    if (body && !(body instanceof FormData)) {
      // Create the header object if needed
      if (!options.headers) {
        options.headers = new HttpHeaders();
      }
      // Sets the content type to json
      options.headers = options.headers.set('Content-Type', 'application/json');
    }

    // Create the request
    const request = new HttpRequest(method, url, body, options);

    // Sends the request
    return this.http.request<T>(request);
  }

  /**
   * Handles the headers from a request.
   * @param response The response
   */
  private handleHeaders<T>(response: HttpResponse<T>) {
    // Emit the event
    this.onHeaderDataReceived.emit(response.headers);
  }
}
