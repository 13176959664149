import {Routes} from '@angular/router';
import {AuthGuard} from './core/gurads/auth/auth.guard';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: '/overview',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: './pages/login/login.module#LoginModule'
  },
  {
    path: 'register',
    loadChildren: './pages/registration/registration.module#RegistrationModule'
  },
  {
    path: '',
    loadChildren: './layout/header/header.module#HeaderModule',
    canActivate: [AuthGuard],
  },
];
